<app-message></app-message>
<app-loader></app-loader>

<div class="abstract_wrap">
  <div class="abstract_grid">
    <!-- Sidebar  -->
    <div class="abstract_banner">
      <div class="wrap_banner_content">
        <div class="sidebar_head">
          <h1>{{iadvlConst?.applicationName}}</h1>
        </div>
        <div class="sidebar_body">
          <div class="brand_icon_home">
            <img src="../assets/img/IADVL logo.jpg" />
            <!-- <img src="../assets/img/IADVL 2024 IADVL logo.jpg"> -->
          </div>

          <!-- <div class="brand_icon">
                   
                  </div> -->
          <div class="banner_title">
            <h4>
              Indian Association of Dermatologists,<br>
              Venereologists and Leprologists
            </h4>
          </div>
         
        </div>
        <div class="sidebar_foot">
          <div class="powered_by">
            <span>Powered by</span>
            <img src="../assets/img/Communa-Logo.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar  -->

    <div class="abstract_content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
