import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-guidelines',
  templateUrl: './guidelines.component.html',
  styleUrls: ['./guidelines.component.scss']
})
export class GuidelinesComponent implements OnInit, OnDestroy {
  @ViewChild('guidlineModal' , {static: true}) public guidlineModal: ModalDirective;
  @Input() class;
  constructor() { }

  ngOnInit() {
  }
  openGuidelinesModal() {
    this.guidlineModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
  };
    this.guidlineModal.show();
  }
  ngOnDestroy() {
    if(this.guidlineModal) {
      this.guidlineModal.hide();
    }
  }
}
