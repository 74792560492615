import {
  Injectable,
  Inject,
  EventEmitter
} from '@angular/core';
import {
  RestService
} from '../core/rest.service';

import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(
    private restService: RestService,) {
  }
  /**
   * register a new user
   * @param userInfo 
   * @returns 
   */
  public register(userInfo) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/users/register`, userInfo).pipe(map((res: any) => res));
  }
  public getMyProfile(){
    return this.restService.get(`${AppConfig.BASE_URL_V1}/profile`, true).pipe(map((res: any) => res));
  }
  public getUsersById(id){
    return this.restService.get(`${AppConfig.BASE_URL_V1}/users/${id}`, true).pipe(map((res: any) => res));
  }
  /**
   * 
   * @param passwordInfo 
   * @returns 
   */
  public changeUserPassword(passwordInfo) {
    return this.restService.put(`${AppConfig.BASE_URL_V1}/profile/changepassword`,passwordInfo).pipe(map((res: any) => res));
  }

  public getUsers(count) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user', { page: count }, true).pipe(map((res: any) => res));
  }
  // public getUser(id) {
  //   return this.restService.get(AppConfig.BASE_URL + '/editaware/user/' + id, true).pipe(map((res: any) => res));
  // }
  public changePassword(id, password) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/credentials/' + id, password).pipe(map((res: any) => res));
  }
  public enableUser(id, enable) {
    let data = {
      enabled: enable
    };
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/credentials/' + id, data, true).pipe(map((res: any) => res));
  }
  public addUser(values) {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      confirmPassword: values.confirmPassword
    };
    return this.restService.post(AppConfig.BASE_URL + '/editaware/user/', data).pipe(map((res: any) => res));
  }
  public updateUser(values, id) {
    let data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      mobile: values.mobile,
      title: values.title,
      jobtitle: values.jobTitle,
      location: values.department,
      organization: values.organization,
    };
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/' + id, data).pipe(map((res: any) => res));
  }
  public searchUser(search, count) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user', { key: search, page: count }, true).pipe(map((res: any) => res));
  }
  public searchUserbyName(search) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/searchUsersByName/' + search, true).pipe(map((res: any) => res));
  }
 
  public updateProfile(values, id) {
    const data = {
      'email': values.email,
      'firstName': values.firstName,
      'lastName': values.lastName,
      'jobtitle': values.jobTitle,
      'title': values.title,
      'organization': values.organization,
      'mobile': values.mobile,
      'middleName': values.middleName,
      'location': values.department,
      'companyTelephone': values.companyTelephone,
      'companyPostcode': values.companyPostcode,
      'companyAddress3': values.companyAddress3,
      'companyAddress2': values.companyAddress2,
      'companyAddress1': values.companyAddress1
    };
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/' + id, data).pipe(map((res: any) => res));
  }
  public deleteUser(id) {
    return this.restService.delete(AppConfig.BASE_URL + '/editaware/user/' + id, null).pipe(map((res: any) => res));
  }
  public forgotPassword(userid, link) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/initiateResetPassword', { userId: userid, resetPasswordUrl: link }, null).pipe(map((res: any) => res));
  }
  public resetPasswordCode(code) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/verifyResetPasswordCode', { code: code }, true).pipe(map((res: any) => res));
  }
  public resetPassword(values) {
    const data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    return this.restService.post(AppConfig.BASE_URL + '/editaware/user/resetpassword/' + values.userId, data).pipe(map((res: any) => res));
  }
}
