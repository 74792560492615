import { CookieService } from 'ngx-cookie-service';
import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs';
// GET UPDATED DATA
// this.communicationService.getUserId.subscribe(message => message = this.userId);
// Update Data
// this.communicationService.setUserId(data);
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private userId =  new BehaviorSubject(null);
  private userName =  new BehaviorSubject(null);
  private adminAuthority =  new BehaviorSubject(false);
  private posterAdminAuthority =  new BehaviorSubject(false);
  private editorGroupAuthority = new BehaviorSubject(false);
  private journalName =  new BehaviorSubject(null);
  // tslint:disable-next-line:member-ordering
  getUserId = this.userId.asObservable();
  // tslint:disable-next-line:member-ordering
  getUserName = this.userName.asObservable();
  // tslint:disable-next-line:member-ordering
  getAdminAuthority = this.adminAuthority.asObservable();

  // tslint:disable-next-line:member-ordering
  getPosterAdminAuthority = this.posterAdminAuthority.asObservable();

  getEditorGroupAuthority = this.editorGroupAuthority.asObservable();
  // tslint:disable-next-line:member-ordering
  getJournalName = this.journalName.asObservable();
  constructor(private CookieService: CookieService) {
    this.initValues();
  }
  initValues() {
    let userName = this.CookieService.get('userName');
    let adminAuthority = this.CookieService.get('adminAuthority');
    let posterAdminAuthority = this.CookieService.get('posterAdminAuthority');
    let editorGroupAuthority = this.CookieService.get('editorGroupAuthority');
    let journalName = this.CookieService.get('journalName');
    if (editorGroupAuthority) {
      let res = JSON.parse(editorGroupAuthority);
      this.editorGroupAuthority.next(res);
    }
    if (adminAuthority) {
      let res = JSON.parse(adminAuthority);
      this.adminAuthority.next(res);
    }
    if (posterAdminAuthority) {
      let res = JSON.parse(posterAdminAuthority);
      this.posterAdminAuthority.next(res);
    }
    let userId = this.CookieService.get('userId');
    if (userId) {
      this.userId.next(userId);
    }
    if (userName) {
      this.userName.next(userName);
    }
    if (journalName) {
      this.journalName.next(journalName);
    }
  }
  setJournalName(journalName) {
    this.journalName.next(journalName);
  }
  setAdminAuthority(val) {
    this.adminAuthority.next(val);
  }
  setPosterAdminAuthority(val) {
    this.posterAdminAuthority.next(val);
  }
  setEditorGroupAuthority(val) {
    this.editorGroupAuthority.next(val);
  }
  setUserId(userId) {
    this.userId.next(userId);
  }
  setUserName(userName) {
    this.userName.next(userName);
  }
  onlogoutUser() {
    this.journalName.next(null);
    this.userName.next(null);
    this.adminAuthority.next(null);
    this.posterAdminAuthority.next(null);
    this.userId.next(null);
  }
}
