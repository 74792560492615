import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { CommunicationService } from 'src/services/communication.service';
import { UserService } from 'src/services/user.service';
import { validateAllFormFields } from 'src/utils/validateform';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit , OnDestroy {
  @ViewChild('guideLineModal', { static: true })
  public guidlineModal: ModalDirective;
  userName;
  status;
  loginedUserEmail;
  loginedUserFirstName;
  loginedUserMiddleName;
  loginedUserLastName;
  loginedUserTitle;
  public formGroup: FormGroup;
  constructor(
    public communicationService: CommunicationService,
    public router: Router,
    public authService: AuthService,
    public CookieService: CookieService,
    public route: ActivatedRoute,
    public userService: UserService,
    private formBuilder: FormBuilder
  ) {
  }
  ngOnInit(): void {
    this.getMyProfile();
    this.formGroup = this.formBuilder.group({
      userName: new FormControl('', [
      ]),
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
      ]),
    }, { validator: this.matchValidator });
  }
  getMyProfile() {
    const token = localStorage.getItem('token');
    if(token){
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      this.loginedUserEmail = decodedToken.data.email;
      this.userName = decodedToken.data.displayName;
    };
  }
  logoutConfirm() {
    this.authService.logout().subscribe((res) => {
      this.CookieService.deleteAll();
      this.CookieService.delete('access_token');
      sessionStorage.setItem('abstractData', '');
      sessionStorage.clear();
      localStorage.clear();
      this.communicationService.onlogoutUser();
      this.router.navigate(['/iadvl']);
      sessionStorage.removeItem('abstractData');
      //location.replace("https://isshp2023.mypage.acad360.com/logout");
    });
    }
    openGuideLineModal() {
      this.guidlineModal.config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: true,
      };
      this.guidlineModal.show();
    }
    ngOnDestroy() {
      if (this.guidlineModal) {
        this.guidlineModal.hide();
      }
    }
    onClickMysubmission() {
      this.router.navigate(['/iadvl/application/mysubmissions'])
    }
    public onSubmit(values) {
      if (this.formGroup.valid) {
        const passwordInfo = {
          oldPassword: values.oldPassword,
          password: values.password
        };
        this.userService.changeUserPassword(passwordInfo).subscribe((res) => {
          if (res.body) {
            this.CookieService.deleteAll();
            this.CookieService.delete('access_token');
            sessionStorage.clear();
            localStorage.clear();
            this.communicationService.onlogoutUser();
            this.router.navigate(['/iadvl']);
          }
        });
      } else {
        validateAllFormFields(this.formGroup);
  
      }
    }
    matchValidator(group: FormGroup) {
      const password: string = group.controls['password'].value; // get password from our password form control
      const confirmPassword: string = group.controls['confirmPassword'].value; // get password from our confirmPassword form control
      // compare is the password math
      if (password !== confirmPassword) {
        group.controls['confirmPassword'].setErrors({ PasswordMisMatch: true });
      }
    }
}
