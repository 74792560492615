import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { GuidelinesComponent } from "./guidelines/guidelines.component";


@NgModule({
  declarations: [
    GuidelinesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
  ],
  exports: [
    GuidelinesComponent,
  ]
})
export class TemplateModalModule {
}