import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageComponent } from 'src/core/message/message.component';
import { LoaderComponent } from 'src/core/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { RestService } from 'src/core/rest.service';
import { CookieService } from 'ngx-cookie-service';
import { CommunicationService } from 'src/services/communication.service';
import { LoaderService } from 'src/core/loader/loader.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from '../services/sharedservice.module';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login';
import { MessageService } from 'primeng/api';
import { TemplateModalModule } from './template/template.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ExternaluserredirectionComponent } from './externaluserredirection/externaluserredirection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangepasswordComponent } from './user/changepassword/changepassword.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';


// import { MessageService } from 'src/core/message/message.service';
//  import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
    LoaderComponent,
    LoginComponent,
    ExternaluserredirectionComponent,
    ChangepasswordComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,
    HttpClientModule,
    SharedModule,
    SharedServiceModule.forRoot(),
    ToastrModule.forRoot(),
    TemplateModalModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
  ],
  providers: [
    LoaderService,
    MessageService,
    // I18nService,
    // MessageService
   
    CookieService,
    // {
    //   deps: [I18nService],  //  service handling global settings
    //   provide: LOCALE_ID,
    //   useFactory: (I18nService) => I18nService.language  //  locale string
    // },
    RestService,
    CookieService,
    // I18nService,
    CommunicationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
})
export class AppModule { }


