import { RestService } from './../../core/rest.service';
import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { CommunicationService } from 'src/services/communication.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateAllFormFields } from 'src/utils/validateform';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'config/app.config';

@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('aboutModal', { static: true })
  public aboutModal: ModalDirective;
  @ViewChild('secretariatModal', { static: true })
  public secretariatModal: ModalDirective;
  public localState = { value: '' };
  validateEmailform: FormGroup;

  // TO MAKE RESPONSIVE
  isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;

  isUserAlredyExists = false;
  validatePasswordform: FormGroup;
  isShowOtpVerifyScreen;
  showInvalidOtp;
  otpValues = Array(4);
  clipData;
  email;
  verification;
  iadvlConst;
  constructor(
    private authService: AuthService,
    private router: Router,
    public ngZone: NgZone,
    public cookieService: CookieService,
    private http:HttpClient,
    public communicationService: CommunicationService) {
    this.lastUrl = this.cookieService.get('lastUrl');
    localStorage.removeItem('abstractData');
    localStorage.clear();
    this.deleteAllCookies();
    const iadvlConst = localStorage.getItem('iadvlConst');
    if(iadvlConst){
      this.iadvlConst = JSON.parse(iadvlConst);
    } else {
      this.getConsts();
    }
  }
  public ngOnInit() {
    this.validateEmailform = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        // Validators.pattern(
        //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // ),
      ]),
    });

    this.validatePasswordform = new FormGroup({
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
    });
    this.oneTimePasswordFormGroup = new FormGroup({
      input1: new FormControl('', [Validators.required]),
      input2: new FormControl('', [Validators.required]),
      input3: new FormControl('', [Validators.required]),
      input4: new FormControl('', [Validators.required]),
    });
    this.otpFromChanges();
  }
  public getConsts() {
    this.http.get('../../assets/const.json').subscribe(res => {
      if(res){
        this.iadvlConst = res;
        localStorage.setItem('iadvlConst',JSON.stringify(res));
      }
    });
  }

  setCookieAndNavigate(userId, adminAuthority, userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
  }

  ngOnDestroy() {
    if (this.aboutModal) {
      this.aboutModal.hide();
    }
    if (this.secretariatModal) {
      this.secretariatModal.hide();
    }
  }
  public deleteAllCookies() {
    const cookies = document.cookie.split(';');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== 'lastUrl') {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  }
  onValidateEmail(values) {
    if (this.validateEmailform.valid) {
      const email = values.Email;
      this.authService.userAlreadyExists(email).subscribe((res) => {
        if (res.body.isExists == true) {
          this.isUserAlredyExists = res.body.isExists;
        } else {
          this.router.navigate(['/iadvl/register'], {
            queryParams: { email },
          });
        }
      });
    } else {
      validateAllFormFields(this.validateEmailform);
    }
  }
  onValidatePassword(values) {
    if (this.validatePasswordform.valid) {
      const data = {
        email: this.validateEmailform.value.Email,
        password: values.Password
      };
      this.authService.login(data).subscribe((res) => {
        if (res.body) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.token);
          localStorage.setItem('token', res.body.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          this.setCookieAndNavigate(decodedToken.data.id, decodedToken.data.isAdmin, decodedToken.data.firstName);
          this.redirectTo();
        }

      },
        (err) => { }
      );
    } else {
      validateAllFormFields(this.validatePasswordform);
    }
  }
  redirectTo() {
    this.router.navigate(['/iadvl/application/mysubmissions']);
  }
  loginUsingOtp() {
    const journalId = this.iadvlConst.currentJournalId || AppConfig.Journal_ID;
    const info = {
      email: this.validateEmailform.value.Email,
      journalId
    };
    this.authService.sendotp(info).subscribe((res) => {
      if (res.body) {
        this.email = res.body.email;
        this.verification = res.body.verification
        this.isUserAlredyExists = null;
        this.isShowOtpVerifyScreen = true;
      }
    });

  }
  otpFromChanges() {
    this.oneTimePasswordFormGroup.valueChanges.subscribe((value) => {
      if (this.oneTimePasswordFormGroup.valid) {
        this.showInvalidOtp = false;
      } 
    });
  }
  public onSubmitOtpFormGroup(value) {
    if (this.oneTimePasswordFormGroup.valid) {
      this.showInvalidOtp = false;
      const otpInfo = {
        email: this.email,
        otp: `${value.input1}${value.input2}${value.input3}${value.input4}`,
        verification: this.verification
      };
      this.authService.loginotp(otpInfo).subscribe((res) => {
        if (res.body) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.token);
          localStorage.setItem('token', res.body.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          this.setCookieAndNavigate(decodedToken.data.id, decodedToken.data.isAdmin, decodedToken.data.firstName);
          this.redirectTo();
        }
      });
    } else {
      this.showInvalidOtp = true;
    }
  }
  openAboutMoodal() {
    this.aboutModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.aboutModal.show();
  }
  openSecretariatModal() {
    this.secretariatModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.secretariatModal.show();
  }
  otpOneCall(event, index) {
    // console.log(event.target);

    const data = event.target.form['otp' + (index - 1)];
    if (event.code === 'Backspace' && this.hasNoValue(index)) {
      if (index > 0) {
        event.target.form['otp' + (index - 1)].focus();
      }
    } //else if any input move focus to next or out
    else if (event.target.value !== '') {
      index < 3
        ? event.target.form['otp' + (index + 1)].focus()
        : event.target.blur();
    }
    this.otpValues[index] = event.target.value;
  }

  hasNoValue(index) {
    if (this.otpValues[index] || this.otpValues[index] === 0) return false;

    return true;
  }
  otpInputs(event, index) {
    if (event.target.value > 10) {
      event.target.value = event.target.value % 10;
    }
  }
  otpPaste(event, index) {
    event.preventDefault();
    this.clipData = event.clipboardData.getData('text/plain').split('');
    this.filldata(event.target.form, index);
  }

  filldata(form, index) {
    for (let i = index; i < 6; i++) {
      form['otp' + i].value = this.clipData.shift();
    }
  }
}
