import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-externaluserredirection',
  templateUrl: './externaluserredirection.component.html',
  styleUrls: ['./externaluserredirection.component.scss']
})
export class ExternaluserredirectionComponent implements OnInit {
  userId;
  securityKey;
  constructor( public route: ActivatedRoute) {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.securityKey = this.route.snapshot.queryParamMap.get('securityKey')
   }

  ngOnInit(): void {
    // api call 
    //if get response in the api redirect to submit page
    // if no respomse in the api call window of there url
  }

}
